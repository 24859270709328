// page header
.page-header {
  background-color: $gray-800;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0rem;
  > h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
    color: #fff;
  }
}
