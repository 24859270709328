html, body, #app {
  height: 100%;
  scroll-behavior: smooth;
}

.is-invalid, .custom-select:focus, :focus {
  box-shadow: none;
}

label {
  color: $body-color;
  margin-bottom: 0 !important;
}
.form-control-lg, .custom-select-lg {
  font-size: $font-size-base;
}

.btn-lg {
  font-size: $font-size-base;
  padding: .7rem 2.5rem;
}
.btn {
  text-transform: uppercase;
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    border: 1px solid #{$value} !important;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color}:hover {
    background-color: lighten($value, 7.5%);
  }
}

.breadcrumb-item {
  font-size: 0.9rem;
}

//remove modal border
.modal-content {
  border: none;
}

//center table
.table tr>td, tr>th {
  vertical-align: middle;
}
th {
  font-weight: 600;
  color: $gray-700;
}

//sweetalert
.swal-button {
  @extend .btn, .btn-lg;
  border: none !important;
  &--confirm {
    @extend .btn-gradient-danger;
  }
  &--cancel {
    @extend .btn-light;
  }
}

.swal-modal {
  border-radius: $border-radius;
}

// element-ui
.el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.el-custom-card > .el-card__header {
  padding: 12px 13px;
}
.el-input-group__append, .el-input-group__prepend {
  color: #c0c4cc;
}
.el-message-box__headerbtn .el-message-box__close {
  color: #909399;
}
.input-group-text {
  padding: 0px 15px;
}

