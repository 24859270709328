div[class^="switch"] input[type=checkbox] {
  position: absolute;
  opacity: 0;
}

.switch {
  font-size: $font-size-base;
}

.switch-lg {
  font-size: $font-size-lg;
}

.switch input[type=checkbox] {
  position: absolute;
  opacity: 0;
}

.switch label {
  width: 2em;
  // height: 1em;
  position: relative;
  cursor: pointer;
  display: block;
}

.switch label:before {
  content: '';
  position: absolute;
  width: 2em;
  height: 1em;
  left: 0.1em;
  transition: background 0.1s ease;
  background: $gray-300;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, .5);
}

.switch label:after {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 50px;
  left: 0;
  transition: all 0.2s ease;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
  animation: switch-off .2s ease-out;
  z-index: 2;
}

.switch input[type=checkbox]:checked + label:before {
  background: $primary-gradient;
  box-shadow: inset 0px 1px 1px linear-gradient(to right, darken(#27a9e0, 5%), darken(#0033c4, 5%))
}

.switch input[type=checkbox]:checked + label:after {
  animation: switch-on .2s ease-out;
  left: 1.1em;
}
