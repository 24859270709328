/* theme color */
$--color-primary: $blue;
$--color-success: $green;
$--color-warning: $yellow;
$--color-danger: $red;
$--color-info: $info;
$--color-text-placeholder: #c0c4cc;


/* icon font path, required */
$--font-path: '/fonts';
$--border-radius-base: 2px !default;
$--border-radius-small: 2px !default;
@import "~element-ui/packages/theme-chalk/src/index";
