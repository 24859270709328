//

// Typography
$font-family-sans-serif: "Sukhumvit", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$badge-font-weight: 400;

// Colors
$blue: #27A9E0;
$dark-blue: #0033c4;
$indigo: #3f51b5;
$purple: #673ab7;
$pink: #e91e63;
$red: #DE3618;
$orange: #F6931E;
$yellow: #EEC200;
$green: #4caf50;
$teal: #00c3b0;
$cyan: #00bcd4;

$white:    #fff !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #424242 !default;
$gray-900: #212121 !default;
$dark:  $gray-800;
$black:    #000 !default;

$primary: $blue;
$info: $teal;

// border-color
$border-color: #dcdfe6;
$input-border-color: $border-color;
$input-focus-border-color: $primary;
$input-placeholder-color: #c0c4cc;
$input-color: #606266;
$input-group-addon-bg: #f5f7fa;
$input-group-addon-color: #c0c4cc;
$modal-header-border-color: $border-color;
$table-border-color: $border-color;
$card-border-color: $border-color !default;

$colors: () !default;
$colors: map-merge(
  (
    "border":       $border-color
  ),
  $colors
);
//gradient
$primary-gradient: linear-gradient(to right, #27a9e0, #0033c4);
$info-gradient: linear-gradient(to right, #27a9e0, #00c3b0);
$success-gradient: linear-gradient(to right, #00b368, #8cc24e);
$danger-gradient: linear-gradient(to right, #e45c35, #ff2a42);

// Body
$body-bg: #f8fafc;
$body-color: $gray-600;
$text-muted: $gray-400;
// border-radius
$border-radius:  2px !default;
$border-radius-lg:  2px !default;
$border-radius-sm:  2px !default;



// box-shadow
$input-focus-box-shadow: inset 1px 1px 1px 0 rgba(0,0,0, 0.05);
