@mixin button-gradient($background1, $background2, $color: #fff) {
  background: linear-gradient(to right, $background1, $background2);
  color: $color;
  border: 0px solid transparent !important;
  &:hover {
    background: linear-gradient(to right, darken($background1, 5%), darken($background2, 5%));
    color: $color;
  }
}


@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $background;
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
      }
    }
  }
}
