.tagify {
  $self: &;
  $tags-border-color: #ddd;
  $tags-hover-border-color: #ccc;
  $tags-focus-border-color: #ccc;

  $tagMargin: 5px;
  $tagPad: 0.3em 0.5em;
  $tag-text-color: #fff;
  $tag-bg: $blue;
  $tag-hover: lighten($blue, 5%);
  $tag-remove: $red;
  $invalid-input-color: $tag-remove;
  $showX: true;
  @mixin placeholder($show: true, $opacity: 0.5) {
    transition: 0.2s ease-out;
    @if $show == true {
      opacity: $opacity;
      transform: none;
    } @else {
      opacity: 0;
      transform: translatex(6px);
    }
  }

  @keyframes tags--bump {
    30% {
      transform: scale(1.2);
    }
  }
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  // border        : 1px solid $tags-border-color;
  padding: 0;
  cursor: text;
  position: relative;

  // &:hover{ border-color:$tags-hover-border-color; }

  // Global "read-only" mode (no input button)
  &[readonly] {
    cursor: default;
    > #{ $self }__input {
      visibility: hidden;
      width: 0;
      margin: $tagMargin 0;
    }
    x {
      display: none;
    }
    tag > div {
      padding: $tagPad;
    }
  }

  ///////////////////////////////////////////
  // Hides originals
  + input,
  + textarea {
    border: 0;
    display: none;
  }

  tag {
    display: inline-block;
    margin: $tagMargin 0 $tagMargin $tagMargin;
    //  vertical-align : top;
    position: relative;
    z-index: 1;
    cursor: default;
    transition: 0.13s ease-out;

    > div {
      // :not([contenteditable])
      vertical-align: top;
      position: relative;
      box-sizing: border-box;
      max-width: 100%;
      padding: $tagPad;
      color: $tag-text-color;
      border-radius: 3px;
      transition: 0.13s ease-out;
      height: 80%;

      > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        //  width          : 100%;
        transition: 0.1s;
      }

      @if $showX {
        padding-right: nth($tagPad, 2) * 3;
      }

      &::before {
        content: '';
        position: absolute;
        border-radius: inherit;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $tag-bg;
        z-index: -1;
        pointer-events: none;
        transition: 80ms ease;
        animation: 0.3s tags--bump 1 ease-out;
      }
    }

    &:hover:not([readonly]) {
      div {
        // :not([contenteditable])
        &::before {
          $size: -$tagMargin/2;
          $size: -2px;
          top: $size;
          right: $size;
          bottom: $size;
          left: $size;
          background: $tag-hover;
          box-shadow: 0 0 0 0 $tag-remove inset;
        }
        // background:nth($tagColor,2);
        //background:none;
        // box-shadow: 0 0 0 2px $tag-hover inset;
        // transition:50ms;
      }
    }

    &#{ $self } {
      &--noAnim {
        animation: none;
      }

      &--hide {
        width: 0 !important;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        opacity: 0;
        transform: scale(0);
        transition: 0.3s;
        pointer-events: none;
      }

      &--mark {
        div::before {
          animation: none;
        }
      }

      &--notAllowed {
        div {
          > span {
            opacity: 0.5;
          } // filter:blur(.2px);
          &::before {
            background: rgba($tag-remove, 0.44);
            transition: 0.2s;
          }
        }
      }
    }

    &[readonly] {
      x {
        display: none;
      }
      > div {
        padding: $tagPad;
      }
    }

    ///////////////////////////////////////////
    // Close button
    x {
      $size: 14px;
      color: #fff;
      font: #{$size}/#{$size + 2} Serif;
      width: $size;
      height: $size;
      text-align: center;
      border-radius: 50px;
      position: absolute;
      z-index: 1;
      right: calc(#{nth($tagPad, 2)} - 2px);
      top: 50%;
      cursor: pointer;
      transform: translateY(-50%);
      transition: 0.2s ease-out;

      &::after {
        content: '\00D7';
      }

      &:hover {
        color: white;
        // background:darken($tag-remove, 8);
        background: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        // + span{ box-shadow: 0 0 0 2px $tag-remove inset; transition:.2s; }
        + div {
          > span {
            opacity: 0.5;
          } // filter:blur(.2px);
          &::before {
            background: rgba($tag-remove, 0.44);
            transition: 0.2s;
          }
        }
      }
    }
  }

  ///////////////////////////////////////////
  // Holds the placeholder & the tags input
  &__input {
    $placeholder-width: 110px;
    @mixin placeholder-show {
      opacity: 0.5;
      transform: none;
    }
    min-width: $placeholder-width;

    margin: $tagMargin;
    padding: $tagPad;
    position: relative;

    &:empty::before {
      @include placeholder;
    }

    &:focus {
      outline: none;
      &::before {
        @include placeholder(false);
        @supports (-moz-appearance: none) {
          display: none;
        }
      }
      &:empty::before {
        @include placeholder(true, 0.3);
        @supports (-moz-appearance: none) {
          display: inline-block;
        }
      }
    }
    // &:empty:focus{ padding:$tagPad; }

    &::before {
      content: attr(data-placeholder);
      line-height: 1.8;
      position: absolute;
      top: 0;
      z-index: 1;
      color: $tag-text-color;
      white-space: nowrap;
      pointer-events: none;
      opacity: 0;
    }

    @supports (-moz-appearance: none) {
      &::before {
        line-height: inherit;
        position: relative;
      }
    }

    // tries to suggest the rest of the value from the first item in the whitelist which matches it
    &::after {
      content: attr(data-suggest);
      color: $tag-text-color;
      opacity: 0.3;
      pointer-events: none;
    }

    &--invalid {
      // color: $invalid-input-color;
    }

    tag {
      margin: 0 $tagMargin/2;

      > div {
        padding-top: 0;
        padding-bottom: 0;

        &::before {
          top: -3px;
          bottom: -3px;
        }
      }

      &:hover:not([readonly]) {
        > div {
          &::before {
            top: -3px;
            bottom: -3px;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  &--mix #{ $self }__input {
    padding: $tagMargin;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  &__dropdown {
    position: absolute;
    z-index: 999;
    background: white;

    max-height: 300px;
    overflow: auto;
    border: 1px solid $tags-focus-border-color;
    box-shadow: 0 2px 4px -2px rgba(black, 0.2);
    box-sizing: border-box;

    &__item {
      box-sizing: inherit;
      padding: 0.35em 0.6em;
      margin: 2px;
      cursor: pointer;
      border-radius: 3px;
      position: relative;

      &--active {
        background: $tag-bg;
      }
      &:active {
        background: lighten($tag-bg, 5);
      }
    }
  }
}
