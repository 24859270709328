// web font
@import url('https://use.fontawesome.com/releases/v5.3.1/css/all.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Sukhumvit';
  src: url('/fonts/sukhumvitset-Bold.eot');
  src: url('/fonts/sukhumvitset-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/sukhumvitset-Bold.woff2') format('woff2'),
  url('/fonts/sukhumvitset-Bold.woff') format('woff'),
  url('/fonts/sukhumvitset-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Sukhumvit';
  src: url('/fonts/sukhumvitset-medium.eot');
  src: url('/fonts/sukhumvitset-medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/sukhumvitset-medium.woff2') format('woff2'),
  url('/fonts/sukhumvitset-medium.woff') format('woff'),
  url('/fonts/sukhumvitset-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Sukhumvit';
  src: url('/fonts/sukhumvitset-text.eot');
  src: url('/fonts/sukhumvitset-text.eot?#iefix') format('embedded-opentype'),
  url('/fonts/sukhumvitset-text.woff2') format('woff2'),
  url('/fonts/sukhumvitset-text.woff') format('woff'),
  url('/fonts/sukhumvitset-text.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Sukhumvit';
  src: url('/fonts/sukhumvitset-thin.eot');
  src: url('/fonts/sukhumvitset-thin.eot?#iefix') format('embedded-opentype'),
  url('/fonts/sukhumvitset-thin.woff2') format('woff2'),
  url('/fonts/sukhumvitset-thin.woff') format('woff'),
  url('/fonts/sukhumvitset-thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}
