.btn-orange {
  @include button-variant($orange, $orange);
  color: #fff;
}

.btn-pill, .is-rounded {
  border-radius: 290486px;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  &::after {
    animation: loading 500ms infinite linear;
    border: .1rem solid $primary;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1.2rem;
    left: 45%;
    margin-left: -.4rem;
    margin-top: -.4rem;
    position: absolute;
    top: 45%;
    width: 1.2rem;
    z-index: 1;
  }
}

.btn.loading, .loading {
  color: transparent !important;
  min-height: inherit;
  pointer-events: none;
  position: relative;
  &::after {
    animation: loading 500ms infinite linear;
    border: .15rem solid $light;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1.2rem;
    left: 45%;
    margin-left: -.4rem;
    margin-top: -.4rem;
    position: absolute;
    top: 40%;
    width: 1.2rem;
    z-index: 1;
  }
}

.btn-lg.loading {
  &::after {
    border-width: .2rem;
    height: 1.6rem;
    margin-left: -.6rem;
    margin-top: -.6rem;
    width: 1.6rem;
  }
}
.btn-sm.loading {
  &::after {
    border-width: .1rem;
    height: .8rem;
    margin-left: -.4rem;
    margin-top: -.4rem;
    width: .8rem;
    top: 50%;
    left: 50%;
  }
}

.btn-gradient {
  &-primary {
    @include button-gradient(#27a9e0, #0033c4);
  }
  &-info {
    @include button-gradient(#27a9e0, #00c3b0);
  }
  &-success {
    @include button-gradient(#00b368, #8cc24e);
  }
  &-danger {
    @include button-gradient(#e45c35, #ff2a42);
  }
}

.btn-white {
  background-color: #fff;
  border: 1px;
  color: $body-color;
  &:hover {
    color: darken($body-color, 5%);
  }
}
